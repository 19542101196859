import React from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import logo from "../assets/img/logo_black.jpg";

import ReactGA from 'react-ga';

import $ from "jquery";

import ReactPixel from 'react-facebook-pixel';

const advancedMatching = { em: 'jevon@xdigital.solutions' }; 
const options = {
  autoConfig: true, 
  debug: false, 
};
ReactPixel.init('142142753138810', advancedMatching, options);

ReactPixel.pageView(); 

ReactGA.initialize('UA-194045758-5');

ReactGA.pageview(window.location.pathname + window.location.search);


class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    $(window).on("scroll", function () {
      var b = $(".page-header ");
      if ($(window).scrollTop() >= 1) {
        b.addClass("page-header--fixed");
      } else {
        b.removeClass("page-header--fixed");
      }
    });

    var s = $(".hamburger");
    if (s.length) {
      var a = $(".menu-dropdown__close");
      var t = $(".menu-dropdown--intro .screen__link");
      $(".hamburger").on("click", function () {
        $(this).toggleClass("hamburger--active");
        $("body").toggleClass("body--static");
        $(".menu-dropdown").toggleClass("menu-dropdown--active");
      });
      a.on("click", function () {
        s.removeClass("hamburger--active");
        $("body").removeClass("body--static");
        $(".menu-dropdown").removeClass("menu-dropdown--active");
      });
      t.on("click", function () {
        s.removeClass("hamburger--active");
        $("body").removeClass("body--static");
        $(".menu-dropdown").removeClass("menu-dropdown--active");
      });
    }



    $(".screen--trigger").on("click", function () {
      var s = $(this).data("category");
      $(".screen--start").addClass("screen--inactive");
      $(".menu-dropdown__inner").each(function () {
        if ($(this).data("value") === s) {
          $(this).addClass("menu-dropdown__inner--active");
        }
      });
    });


    $(".screen__back").on("click", function () {
      $(".menu-dropdown__inner").removeClass("menu-dropdown__inner--active");
      $(".screen--start").removeClass("screen--inactive");
    });
    
  }
  render() {
    return (
      <div>
        
        <div className="menu-dropdown">
          <div className="menu-dropdown__inner menu-dropdown--intro" data-value="start">
            <div className="screen screen--start">
              <div className="menu-dropdown__close">
                <svg className="icon">
                  <use xlinkHref="#close"></use>
                </svg>
              </div>
              <div className="d-block d-lg-none bottom-20">
                <div className="screen__item screen--trigger">
                  <NavLink
                    strict
                    exact
                    to="new-vehicles"
                    to={`${process.env.PUBLIC_URL}/new-vehicles`}
                    key={`${process.env.PUBLIC_URL}/new-vehiclesasd`}
                    className="screen__link"
                    onClick={this.hideMenu}
                  >
                    New Vehicles
                  </NavLink>
                </div>
                <div className="screen__item screen--trigger">
                  <NavLink
                    strict
                    exact
                    to="pre-owned"
                    to={`${process.env.PUBLIC_URL}/pre-owned`}
                    key={`${process.env.PUBLIC_URL}/pre-ownedasdasd`}
                    className="screen__link "
                    onClick={this.hideMenu}
                  >
                    Pre-Owned
                  </NavLink>
                </div>
              </div>
              <div className="screen__item">
                <NavLink
                  strict
                  exact
                  to="leasing"
                  to={`${process.env.PUBLIC_URL}/leasing`}
                  key={`${process.env.PUBLIC_URL}/leasing`}
                  className="screen__link"
                  onClick={this.hideMenu}
                >
                  Leasing
                </NavLink>
              </div>

              <div
                class="screen__item screen--trigger"
                data-category="screen-one"
              >
                <span>After-sales</span>
                <span>
                  <svg class="icon">
                    <use xlinkHref="#chevron-right"></use>
                  </svg>
                </span>
              </div>
              <div className="screen__item">
              <NavLink
                  strict
                  exact
                  to='about-us'
                  to={`${process.env.PUBLIC_URL}/about-us`}
                  key={`${process.env.PUBLIC_URL}/about-us`}
                  className=" screen__link"
                  activeClassName=""
                >
                 About us
                </NavLink>
                </div>
                <div className="screen__item">
							<NavLink
                  strict
                  exact
                  to='about-us'
                  to={`${process.env.PUBLIC_URL}/careers`}
                  key={`${process.env.PUBLIC_URL}/careers`}
                  className=" screen__link"
                  activeClassName=""
                >
                 Careers
                </NavLink>
                </div>
                <div className="screen__item">

                	<NavLink
                  strict
                  exact
                  to='happenings'
                  to={`${process.env.PUBLIC_URL}/happenings`}
                  key={`${process.env.PUBLIC_URL}/happenings`}
                  className=" screen__link"
                  activeClassName=""
                >
                 Happenings
                </NavLink>
                </div>
                <div className="screen__item">
                    <a href="https://www.skylinkmotor.com/" class=" screen__link" target="_blank">
                    Motorcycle
                    </a>
                  </div>
              <div className="menu-dropdown__block top-50">
                <span className="block__title">Email</span>
                <a
                  className="screen__link"
                  href="mailto:sales@skylinkauto.com.sg"
                >
                  sales@skylink.com.sg
                </a>
              </div>
              <div className="menu-dropdown__block top-20">
                <span className="block__title">Phone numbers</span>
                <a className="screen__link" href="tel:+6266 5858">
                6266 5858{" "}
                </a>
                <a className="screen__link" href="tel:+9233 5858">
                9233 5858
                </a>
              </div>
              <div className="menu-dropdown__block">
                <ul className="socials list--reset">
              
                  <li className="socials__item">
                    <a className="socials__link" href="https://www.facebook.com/skylinkauto">
                      <svg className="icon">
                        <use xlinkHref="#facebook"></use>
                      </svg>
                    </a>
                  </li>
  
                  <li className="socials__item">
                    <a className="socials__link" href="https://www.linkedin.com/company/skylink-auto">
                      <svg className="icon">
                        <use xlinkHref="#linkedin"></use>
                      </svg>
                    </a>
                  </li>
                  <li className="socials__item">
                    <a className="socials__link" href="https://www.instagram.com/skylink_auto/">
                      <svg className="icon">
                        <use xlinkHref="#inst"></use>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="menu-dropdown__block top-50 w-100">

              <NavLink
                    strict
                    exact
                    to={`${process.env.PUBLIC_URL}/contact-us`}
                    key={`${process.env.PUBLIC_URL}/contact-us`}
                    className="button button--filled w-100 screen__link"
                   style={{'lineHeight' :'70px'}}>
                    Contact us

               </NavLink>
               
              </div>
            </div>
          </div>

          <div className="menu-dropdown__inner menu-dropdown--intro" data-value="screen-one">
            <div className="screen screen--sub">
              <div className="screen__heading">
                <h6 className="screen__back">
                  <svg className="icon">
                    <use xlinkHref="#chevron-left"></use>
                  </svg>{" "}
                  <span>After-sales</span>
                </h6>
              </div>
              <div className="screen__item">
              <NavLink
                          strict
                          exact
                          to="insurance"
                          to={`${process.env.PUBLIC_URL}/insurance`}
                          key={`${process.env.PUBLIC_URL}/insurance`}
                          className="screen__link"
                          activeClassName=""
                        >
                          Insurance
                        </NavLink>
               
              </div>
              <div className="screen__item">
              <NavLink
                          strict
                          exact
                          to="finance"
                          to={`${process.env.PUBLIC_URL}/finance`}
                          key={`${process.env.PUBLIC_URL}/finance`}
                          className=" screen__link"
                        >
                          Finance
                        </NavLink>
              </div>
              <div className="screen__item">
              <NavLink
                          strict
                          exact
                          to="workshop"
                          to={`${process.env.PUBLIC_URL}/workshop`}
                          key={`${process.env.PUBLIC_URL}/workshop`}
                          className=" screen__link"
                        >
                          Workshop
                        </NavLink>
              </div>
            </div>
          </div>
          <div className="menu-dropdown__inner" data-value="screen-three">
            <div className="screen screen--sub">
              <div className="screen__heading">
                <h6 className="screen__back">
                  <svg className="icon">
                    <use xlinkHref="#chevron-left"></use>
                  </svg>{" "}
                  <span>Services</span>
                </h6>
              </div>
            </div>
          </div>
        </div>

        <header className="page-header ">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-8 col-md-6 col-lg-2 d-flex align-items-center">
                <div className="page-header__logo">
                  <NavLink
                    strict
                    exact
                    to="new-vehicles"
                    to={`${process.env.PUBLIC_URL}/`}
                    key={`${process.env.PUBLIC_URL}/`}
                    className=""
                    onClick={this.hideMenu}
                  >
                    <img src={logo} alt="logo" />
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-8 d-none d-lg-flex justify-content-end">
                <ul className="main-menu">
                  <li className="main-menu__item ">
                    <NavLink
                      strict
                      exact
                      to="new-vehicles"
                      to={`${process.env.PUBLIC_URL}/new-vehicles`}
                      key={`${process.env.PUBLIC_URL}/new-vehicles`}
                      className="main-menu__link "
                      activeClassName="main-menu__link  main-menu__item--active"
                      onClick={this.hideMenu}
                    >
                      New Vehicles
                    </NavLink>
                  </li>
                  <li className="main-menu__item ">
                    <NavLink
                      strict
                      exact
                      to="pre-owned"
                      to={`${process.env.PUBLIC_URL}/pre-owned`}
                      key={`${process.env.PUBLIC_URL}/pre-owned`}
                      className="main-menu__link "
                      activeClassName="main-menu__link  main-menu__item--active"
                      onClick={this.hideMenu}
                    >
                      Pre-Owned
                    </NavLink>
                  </li>
                  <li className="main-menu__item ">
                    <NavLink
                      strict
                      exact
                      to="leasing"
                      to={`${process.env.PUBLIC_URL}/leasing`}
                      key={`${process.env.PUBLIC_URL}/leasing`}
                      className="main-menu__link "
                      activeClassName="main-menu__link  main-menu__item--active"
                      onClick={this.hideMenu}
                    >
                      Leasing
                    </NavLink>
                  </li>
                  <li className="main-menu__item main-menu__item--has-child">
                    <a className="main-menu__link" href="javascript:void(0);">
                      <span>After-sales</span>
                    </a>

                    <ul className="main-menu__sub-list">
                      <li>
                        <NavLink
                          strict
                          exact
                          to="insurance"
                          to={`${process.env.PUBLIC_URL}/insurance`}
                          key={`${process.env.PUBLIC_URL}/insurance`}
                          className=" "
                          activeClassName="main-menu__item--active"
                          onClick={this.hideMenu}
                        >
                          Insurance
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          strict
                          exact
                          to="finance"
                          to={`${process.env.PUBLIC_URL}/finance`}
                          key={`${process.env.PUBLIC_URL}/finance`}
                          className=" "
                          activeClassName="main-menu__item--active"
                          onClick={this.hideMenu}
                        >
                          Finance
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          strict
                          exact
                          to="workshop"
                          to={`${process.env.PUBLIC_URL}/workshop`}
                          key={`${process.env.PUBLIC_URL}/workshop`}
                          className=" "
                          activeClassName="main-menu__item--active"
                          onClick={this.hideMenu}
                        >
                          Workshop
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="main-menu__item ">
                    <NavLink
                      strict
                      exact
                      to="leasing"
                      to={`${process.env.PUBLIC_URL}/happenings`}
                      key={`${process.env.PUBLIC_URL}/happenings`}
                      className="main-menu__link "
                      activeClassName="main-menu__link  main-menu__item--active"
                      onClick={this.hideMenu}
                    >
                      Happenings
                    </NavLink>
                  </li>
                  <li className="main-menu__item ">
                    <NavLink
                      strict
                      exact
                      to="leasing"
                      to={`${process.env.PUBLIC_URL}/careers`}
                      key={`${process.env.PUBLIC_URL}/careers`}
                      className="main-menu__link "
                      activeClassName="main-menu__link  main-menu__item--active"
                      onClick={this.hideMenu}
                    >
                      Careers
                    </NavLink>
                  </li>
                  <li className="main-menu__item ">
                    <a href="https://www.skylinkmotor.com/" class="button button--dark" target="_blank">
                    Motorcycle
                    </a>
                  </li>
                  
                </ul>
              </div>
              <div className="col-4 col-md-6 col-lg-2 d-flex justify-content-end align-items-center">
                <div className="main-menu__item ">
                  <NavLink
                    strict
                    exact
                    to={`${process.env.PUBLIC_URL}/contact-us`}
                    key={`${process.env.PUBLIC_URL}/contact-us`}
                    className="button button--filled"
                    onClick={this.hideMenu}
                  >
                    Contact us
                    <svg
                      height="15"
                      width="15"
                      viewBox="0 0 512  512 "
                      fill="#fff"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-4"
                    >
                      <path d="M508 48c-2-2-6-3-9-1L7 214a10 10 0 0 0-1 19l122 58 66 169a10 10 0 0 0 15 4l103-81 131 67a10 10 0 0 0 15-7l54-386c0-3-1-6-4-9zM36 225L423 94 136 273zm167 203l-54-140 297-185-207 228-2 3zm22-2l27-74 40 21zm215 0l-177-91L488 87z" />
                    </svg>
                  </NavLink>

                  <div className="hamburger d-inline-block d-md-none">
                    <div className="hamburger-inner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Nav;
