import React, { Component } from "react";
import { NavLink, Link , useLocation } from "react-router-dom";
import promo_1 from "../assets/img/promo_1.jpg";
import MediaQuery from "react-responsive";
import { useMediaQuery } from "react-responsive";
import Slider from "slick-carousel";
import axios from 'axios';
import $ from "jquery";
import {
	LazyLoadImage,
	trackWindowScroll,
  } from "react-lazy-load-image-component";

import LoadAnimationSmall from "../components/LoadAnimationSmall";
class HomeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: [],
      bannerdata: [],
      isLoaded: false,
      };
  }

  componentDidMount() {

    
    var s = $(".promo-slider");
    var a = $(".promo-slider__count");

    s.on("init afterChange", function (event, slick, currentSlide, nextSlide) {
      var o = (currentSlide || 0) + 1;
      a.html(o + "<b>/</b>" + slick.slideCount);
    });

 

    axios.get(`https://www.skylink.com.sg/cms/wp-json/wp/v2/homebanner`)
    .then(res => {

		const bannerdata = res.data;
		this.setState({ bannerdata });
		this.setState({ banners: bannerdata });
    //console.log(this.state.banners);


    $(".promo-slider").slick({
      fade: !0,
      adaptiveHeight: !0,
      infinite: !0,
      speed: 1200,
      arrows: !0,
      dots: !0,
      appendDots: $(".promo-slider__nav"),
      appendArrows: $(".promo-slider__navArrows"),
      prevArrow:
        '<button class="slide-arrow prev-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492" width="50"><path d="M198.6 246.1l184-184a26.7 26.7 0 0 0 0-38l-16-16.2C361.4 2.8 354.6 0 347.4 0s-14 2.8-19 7.9L109.3 227c-5 5-7.8 11.9-7.8 19 0 7.3 2.7 14.1 7.8 19.2l219 219c5 5 11.8 7.8 19 7.8 7.2 0 14-2.8 19-7.9l16.2-16a27 27 0 0 0 0-38.1L198.6 246z"/></svg></button>',
      nextArrow:
        '<button class="slide-arrow next-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492" width="50" height="50"><path d="M382.7 226.8l-219-219c-5-5-11.8-7.8-19-7.8s-14 2.8-19 7.9l-16.2 16a27 27 0 0 0 0 38.1L293.4 246l-184 184a26.8 26.8 0 0 0 0 38l16 16.2a26.9 26.9 0 0 0 38 0L382.8 265c5-5 7.8-11.9 7.8-19 0-7.3-2.7-14.1-7.8-19.2z"/></svg></button>',
    });
    $(".promo-slider1").slick({
      fade: !0,
      adaptiveHeight: !0,
      infinite: !0,
      speed: 1200,
      arrows: !0,
      dots: !0,
      appendDots: $(".promo-slider__nav"),
      appendArrows: $(".promo-slider__navArrows"),
      prevArrow:
        '<button class="slide-arrow prev-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492" width="50"><path d="M198.6 246.1l184-184a26.7 26.7 0 0 0 0-38l-16-16.2C361.4 2.8 354.6 0 347.4 0s-14 2.8-19 7.9L109.3 227c-5 5-7.8 11.9-7.8 19 0 7.3 2.7 14.1 7.8 19.2l219 219c5 5 11.8 7.8 19 7.8 7.2 0 14-2.8 19-7.9l16.2-16a27 27 0 0 0 0-38.1L198.6 246z"/></svg></button>',
      nextArrow:
        '<button class="slide-arrow next-arrow"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492" width="50" height="50"><path d="M382.7 226.8l-219-219c-5-5-11.8-7.8-19-7.8s-14 2.8-19 7.9l-16.2 16a27 27 0 0 0 0 38.1L293.4 246l-184 184a26.8 26.8 0 0 0 0 38l16 16.2a26.9 26.9 0 0 0 38 0L382.8 265c5-5 7.8-11.9 7.8-19 0-7.3-2.7-14.1-7.8-19.2z"/></svg></button>',
    });
    setTimeout(function(){    $('.promo-slider').slick('refresh');},1000);





 
    

  });

 

  }



  render() {
    const sliderstyle = {  
      background:"#d9d9d9", 
      };  
    let {
     
      isLoaded,
      banners,
      scrollPosition,
      } = this.state;
    return (
      <div className="promo">
        <div >
        {isLoaded ? (
                   <div className="promo-slider1 w-100v ">
                     <div className="promo-slider__item">
                      <div className="promo-slider__layout"></div>


                      <MediaQuery minDeviceWidth={768}>
                      <picture>
                        <source srcset={promo_1}  media="(min-width: 992px)" />
                        <LazyLoadImage
                                    key='asdasdasd'
                                    alt='asdasd'
                                    className="img--bg"
                                    scrollPosition={scrollPosition}
                                    src={promo_1} 
                                    />
                        {/* // <img className="img--bg" src={item.meta.bannerimg}  alt="img" /> */}
                      </picture>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={767}>
        <picture>
                        <source srcset={promo_1}  media="(min-width: 992px)" />
                        <LazyLoadImage
                                    key='asdasdasd'
                                    alt='asdasd'
                                    className="img--bg"
                                    scrollPosition={scrollPosition}
                                    src={promo_1} 
                                    />
                        {/* // <img className="img--bg" src={item.meta.bannerimg}  alt="img" /> */}
                      </picture>
        </MediaQuery>


                   
                      <div className="align-container">
                        <div className="align-container__item">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-9 col-xl-7">
                                <div className="promo-slider__wrapper-1">
                                  <span className="promo-slider__overlay">Skylink</span>
                                  <h2 class="promo-slider__title"><p>Your Most Trusted,</p>
<p>Award-Winning Automotive</p>
<p>Dealership in Singapore</p>
</h2>
                                </div>
                              
                               
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
             
  </div>
                     ) : (
                      <div className="promo-slider w-100v " style={sliderstyle}>

                        
                      {this.state.banners.length > 0 ? (
               this.state.banners.map(item => {
                       
                          return (
                            <div className="promo-slider__item">
                      <div className="promo-slider__layout"></div>

                      <MediaQuery minDeviceWidth={768}>
                      <picture>
                        <source srcset={item.meta.bannerimg}  media="(min-width: 992px)" />
                        <LazyLoadImage
                                    key={item.meta.title}
                                    alt={item.meta.title}
                                    className="img--bg"
                                    scrollPosition={scrollPosition}
                                    src={item.meta.bannerimg}
                                    />
                        {/* // <img className="img--bg" src={item.meta.bannerimg}  alt="img" /> */}
                      </picture>
        </MediaQuery>

        <MediaQuery maxDeviceWidth={767}>
                      <picture>
                        <source srcset={item.meta.bannerimgSmall}  media="(min-width: 992px)" />
                        <LazyLoadImage
                                    key={item.meta.title}
                                    alt={item.meta.title}
                                    className="img--bg"
                                    scrollPosition={scrollPosition}
                                    src={item.meta.bannerimgSmall}
                                    />
                        {/* // <img className="img--bg" src={item.meta.bannerimg}  alt="img" /> */}
                      </picture>
        </MediaQuery>


                      
                      <div className="align-container">
                        <div className="align-container__item">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-9 col-xl-7">
                                <div className="promo-slider__wrapper-1">
                                  <span className="promo-slider__overlay">{item.meta.title}</span>
                                  <h2 className="promo-slider__title" dangerouslySetInnerHTML={{__html: item.meta.description}}>
                                   
                                  </h2>
                                </div>
                              
                               
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                      
                          )
                         
                       }
                       )
                       ) : (
                        <div >
                         <div >
                         
                        </div>
                         
                        </div>
                      )}
                                     
      </div>
                    
                      )}


        </div>
        <div className="promo-slider__navArrows"></div>
        <div className="promo__lower">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5 col-lg-7 col-xl-8 d-flex align-items-start">
                <div className="promo-slider__nav"></div>

                <div className="promo-slider__count"></div>
              </div>
              <div className="col-md-7 col-lg-5 col-xl-4 top-20 top-md-0"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeSlider;
