import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
//import { play, exit } from './components/Timelines/Timelines'
import Home from './pages/Home';

const terms = React.lazy(() => import('./pages/terms-of-use'));
const leasing = React.lazy(() => import('./pages/leasing'));
const insurance = React.lazy(() => import('./pages/insurance'));
const finance = React.lazy(() => import('./pages/finance'));
const workshop = React.lazy(() => import('./pages/workshop'));
const happenings = React.lazy(() => import('./pages/happenings'));
const contactus = React.lazy(() => import('./pages/contactus'));
const NewVehicles = React.lazy(() => import('./pages/new-vehicles'));
const InnerVehicles = React.lazy(() => import('./pages/inner-vehicle'));
const PreOwned = React.lazy(() => import('./pages/pre-owned'));
const InnerHappenings = React.lazy(() => import('./pages/inner-happenings'));
const privacyPolicy = React.lazy(() => import('./pages/privacy-policy'));
const thankyYou = React.lazy(() => import('./pages/thank-you'));

const Careers = React.lazy(() => import('./pages/careers'));
const Aboutus = React.lazy(() => import('./pages/aboutus'));

class Routes extends Component {
  
  render() {
    return (
          <Route render={({ location }) => {
            const { pathname, key } = location;

           
              
            const onEnter = node => {
     
         
            };

            const onExit = node => {
              
          
            };

            return (
         
               

          
                  <Switch basename={`${process.env.PUBLIC_URL}/`} location={location} path={`${process.env.PUBLIC_URL}/`}>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
                    <Route path={`${process.env.PUBLIC_URL}/leasing`} component={leasing} />
                    <Route path={`${process.env.PUBLIC_URL}/insurance`} component={insurance} />
                    <Route path={`${process.env.PUBLIC_URL}/finance`} component={finance} />
                    <Route path={`${process.env.PUBLIC_URL}/workshop`} component={workshop} />
                    
                    <Route path={`${process.env.PUBLIC_URL}/happenings/:id`} component={InnerHappenings} />
                    <Route path={`${process.env.PUBLIC_URL}/happenings`} component={happenings} />
                    <Route path={`${process.env.PUBLIC_URL}/contact-us`} component={contactus} />
                    <Route path={`${process.env.PUBLIC_URL}/new-vehicles`} component={NewVehicles} />
                    <Route path={`${process.env.PUBLIC_URL}/pre-owned`} component={PreOwned} />
                    <Route path={`${process.env.PUBLIC_URL}/inner-vehicle`} component={InnerVehicles} />
                    <Route path={`${process.env.PUBLIC_URL}/about-us`} component={Aboutus} />
                    <Route path={`${process.env.PUBLIC_URL}/careers`} component={Careers} />
                    <Route path={`${process.env.PUBLIC_URL}/privacy-policy`} component={privacyPolicy} />
                    <Route path={`${process.env.PUBLIC_URL}/terms`} component={terms} />
                    <Route path={`${process.env.PUBLIC_URL}/thank-you`} component={thankyYou} />

                    <Route path={`${process.env.PUBLIC_URL}/vehicles/:id`} component={InnerVehicles} />

     
                    
                    
                  </Switch>
                 
            
            
            )
          }} />
  
    )
 
  }
}
export default Routes;



