import React, { Component } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";

import HomeSlider from "../components/HomeSlider";

import testimonials from "../assets/img/testimonials-bg.webp";
import about_img from "../assets/img/about_img.webp";
import clinet1 from "../assets/img/client1.png";
import clinet2 from "../assets/img/client2.png";
import clinet3 from "../assets/img/client3.png";
import clinet4 from "../assets/img/client4.png";
import clinet5 from "../assets/img/client5.png";
import clinet6 from "../assets/img/client6.png";
import { Helmet } from "react-helmet";

import $ from "jquery";

import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";

const BrandSlider = React.lazy(() => import("../components/BrandSlider"));
const VehicalMain = React.lazy(() => import("../components/VehicalMain"));
const Services = React.lazy(() => import("../components/Services"));
const TestimonalSec = React.lazy(() => import("../components/TestimonalSec"));
const BlogSec = React.lazy(() => import("../components/BlogSec"));
const CTAForm = React.lazy(() => import("../components/CTAForm"));
const PopUp = React.lazy(() => import("../components/PopUp"));

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageName: "Home page",
      pageurl: "www.skylink.com.sg/",
    };
  }
  componentDidMount() {}

  render() {
    let { scrollPosition } = this.state;
    return (
      <div>
        <Helmet>
          {" "}
          <title>Home page | Skylink Group Holdings</title>
          <meta
            name="description"
            content="Looking to Lease, Rent or Buy commercial vehicles in Singapore? Skylink Holdings offers new and pre-owned commercial van and truck for sale in Singapore. "
          />
          <link rel="canonical" href="https://www.skylink.com.sg/" />
        </Helmet>

        <HomeSlider />
        <BrandSlider />

        <VehicalMain />

        <section className="section front-about">
          <div className="front-about__bg">
            <img className="section--bg t50 l0" src={testimonials} alt="img" />
          </div>
          <div className="front-about__img">
            <LazyLoadImage
              key="asdasdasdaxcxca"
              alt="ABOUT SKYLINK GROUP"
              className="img--bg"
              scrollPosition={scrollPosition}
              src={about_img}
            />

            {/* <img className="img--bg" src={about_img} alt="img"/> */}
            <a
              className="js-video front-about__video"
              target="_blank"
              href="https://www.youtube.com/watch?v=Jach52XLGRI"
            >
              <span>Walkthrough</span>
              <svg
                viewBox="0 0 494.942 494.942"
                id="play"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                className="ml-4"
              >
                <path d="M35.353 0l424.236 247.471L35.353 494.942z"></path>
              </svg>
            </a>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-7">
                <div className="heading heading--white mb-2">
                  <span className="heading__pre-title">
                    ABOUT SKYLINK GROUP
                  </span>
                  <br />

                  <h6>Committed to service</h6>
                </div>
                <p>
                  Established with business owners in mind, we are commited to
                  service with excellence & reliability.
                </p>
                <h6>A one stop solution</h6>
                <p>
                  Offering a series of comprehensive services - purchase,
                  insurance, financing, workshop servicing and repairs, and
                  customized engineering works for your commercial vehicle
                  needs.{" "}
                </p>

                <h6>Wide range of vehicles </h6>
                <p>
                  Full range of commercial vehicles - mini vans, standard cargo
                  vans, light trucks, medium trucks, heavy duty trucks, mini
                  buses and more.
                </p>

                <h6>Our Partners and Customers </h6>
                <div class="align-items-center row mb-2">
                  <div class="col-md-2">
                    <img className="img-fluid" src={clinet2} alt="img" />
                  </div>
                  <div class="col-md-10">
                  <p class="mb-0">
                      Sankyu (Singapore) Pte Ltd
                    </p>
                  </div>
                </div>
                <div class="align-items-center row mb-2">
                  <div class="col-md-2">
                    <img className="img-fluid" src={clinet6} alt="img" />
                  </div>
                  <div class="col-md-10">
                  <p class="mb-0">
                     United Parcel Services (UPS)
                    </p>
                  </div>
                </div>
                <div class="align-items-center row mb-2">
                  <div class="col-md-2">
                    <img className="img-fluid" src={clinet3} alt="img" />
                  </div>
                  <div class="col-md-10">
                  <p class="mb-0">
                    Sembcorp Industries 
                 
                    </p>
                  </div>
                </div>
                <div class="align-items-center row mb-2">
                  <div class="col-md-2">
                    <img className="img-fluid" src={clinet4} alt="img" />
                  </div>
                  <div class="col-md-10">
                  <p class="mb-0">
                    Sheng Siong Supermarket Pte Ltd
                   
                    </p>
                  </div>
                </div>
                <div class="align-items-center row mb-2">
                  <div class="col-md-2">
                    <img className="img-fluid" src={clinet1} alt="img" />
                  </div>
                  <div class="col-md-10">
                  <p class="mb-0">
                    The National Environment Agency (NEA)
                    </p>
                  </div>
                </div>
                <div class="align-items-center row mb-2">
                  <div class="col-md-2">
                    <img className="img-fluid" src={clinet5} alt="img" />
                  </div>
                  <div class="col-md-10">
                    <p class="mb-0">
                    Singapore Post Limited (SingPost)
                    </p>
                  </div>
                </div>
               

                <NavLink
                  strict
                  exact
                  to="about-us"
                  to={`${process.env.PUBLIC_URL}/about-us`}
                  key={`${process.env.PUBLIC_URL}/about-us`}
                  className="button button--promo"
                >
                  <span>More about</span>
                  <svg className="icon">
                    <use xlinkHref="#arrow"></use>
                  </svg>
                </NavLink>
              </div>
              <div className="col-xl-4 d-none d-xl-flex offset-50">
                <div className="counter counter--filled">
                  <div className="counter__lower">
                    <span className="counter__details">Established in</span>
                  </div>
                  <div className="counter__top">
                    <span className="js-counter counter__count">2017 </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Services />
        <TestimonalSec />
        <BlogSec />
        {/* <PopUp /> */}
        <CTAForm
          dataFromParent={this.state.pageName}
          dataFromurl={this.state.pageurl}
        />
      </div>
    );
  }
}

export default Index;
