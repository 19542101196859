import React, { Component } from "react";

class LoadAnimationSmall extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
 

    return (
      <div className="row justify-content-center mobile-slider">
        <div className="col-md-4 shop-main">
          <div className="product-item product-item--style-2 product-item--wide loading">
            <div className="img--bg loading" ></div>

            <div className="product-item__content">
              <h6 className="product-item__title"></h6>
            </div>
            <div className="product-item__details"> </div>
          </div>
        </div>
        <div className="col-md-4 shop-main">
          <div className="product-item product-item--style-2 product-item--wide loading">
            <div className="img--bg loading" ></div>

            <div className="product-item__content">
              <h6 className="product-item__title"></h6>
            </div>
            <div className="product-item__details"> </div>
          </div>
        </div>
        <div className="col-md-4 shop-main">
          <div className="product-item product-item--style-2 product-item--wide loading">
            <div className="img--bg loading" ></div>

            <div className="product-item__content">
              <h6 className="product-item__title"></h6>
            </div>
            <div className="product-item__details"> </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoadAnimationSmall;
