import Nav from "./nav";


const Header = () => {

    
    return (
        <div>
            <Nav/>
      
        </div>
    );
};

export default Header;